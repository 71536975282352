import React, { useState } from "react";

const DonorsCard = ({ image, name, designation, sayings }) => {
  const [showModal, setShowModal] = useState(false);

  const handleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="border rounded-lg shadow-md p-6 bg-white max-w-3xl mx-auto">
      <div className="flex flex-col sm:flex-row items-center gap-6">
        {/* Image Section */}
        <img
          src={image}
          alt={`${name}'s profile`}
          className="w-full sm:w-1/2 h-56 object-cover rounded-md"
        />

        {/* Text Section */}
        <div className="flex-1 text-center sm:text-left">
          <h3 className="text-xl font-bold">{name}</h3>
          <p className="text-base text-gray-600 mt-2">{designation}</p>
          <button
            onClick={handleModal}
            className="mt-4 bg-[#1d3f586c] text-white px-6 py-2 rounded hover:bg-[#3d87e76c]"
          >
            Learn More
          </button>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={handleModal}
        >
          <div
            className="bg-white rounded-lg p-8 w-11/12 max-w-3xl"
            onClick={(e) => e.stopPropagation()}
          >
            <img
              src={image}
              alt={`${name}'s profile`}
              className="w-32 h-32 object-cover rounded-full mx-auto"
            />
            <h3 className="text-2xl font-bold text-center mt-6">{name}</h3>
            <p className="text-base text-gray-600 text-center">{designation}</p>
            <p className="mt-6 text-gray-700">{sayings}</p>
            <button
              onClick={handleModal}
              className="mt-8 bg-red-500 text-white px-6 py-3 rounded hover:bg-red-600 w-full"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DonorsCard;

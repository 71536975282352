import React from "react";
import CompletedCard from "../../Components/Cards/CompletedCard";

const Completed = () => {
  const cardsData = [
    {
      title: "Card title 1",
      date: "Jun 28, 2018 • 2 min read",
      views: "523",
      likes: "4",
      image: "https://via.placeholder.com/600x400",
      subtitle: "Dreamers Bangladesh",
    },
    {
      title: "Card title 2",
      date: "May 13, 2018 • 2 min read",
      views: "189",
      likes: "2",
      image: "https://via.placeholder.com/600x400",
      subtitle: "Dreamers Bangladesh",
    },
    {
      title: "Card title 3",
      date: "Aug 15, 2021 • 3 min read",
      views: "300",
      likes: "8",
      image: "https://via.placeholder.com/600x400",
      subtitle: "Dreamers Bangladesh",
    },
  ];

  return (
    <div className="flex flex-wrap gap-8 p-4 justify-center items-start">
      {cardsData.map((card, index) => (
        <CompletedCard
          key={index}
          title={card.title}
          date={card.date}
          views={card.views}
          likes={card.likes}
          image={card.image}
          subtitle={card.subtitle}
        />
      ))}
    </div>
  );
};

export default Completed;

import React from "react";
import image from "../../images/slider1.png";
const Vision = () => {
  return (
    <section className="flex flex-col items-center p-4 bg-gray-100">
      <h2 className="text-xl sm:text-2xl font-bold uppercase text-gray-800 tracking-wide mb-4">
        Vision
      </h2>
      <div className="w-full">
        <img
          src={image}
          alt=""
          role="img"
          className="object-fit w-full h-[200px] sm:h-[300px] md:h-[400px] lg:h-[500px] rounded-md"
        />
      </div>
      <div className="mt-4 text-center">
        <p className="text-sm sm:text-base md:text-lg lg:text-xl text-gray-700 text-start max-w-3xl">
          A society where every young person is empowered to realise their
          potential and actively participate in building a more inclusive,
          equitable, and sustainable world for future generations.
        </p>
      </div>
    </section>
  );
};

export default Vision;

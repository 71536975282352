import React from "react";

const coreValues = [
  {
    icon: "🌟", // Replace with an actual icon/image if available
    title: "Honesty in Words and Actions",
    description:
      "We commit to transparency and sincerity, ensuring all interactions are genuine. Promises align with actions, fostering credibility and respect.",
  },
  {
    icon: "🚀",
    title: "Lead by Example",
    description:
      "Our leaders are actively involved, working alongside others to achieve shared goals. Through action, they inspire teamwork and initiative.",
  },
  {
    icon: "📚",
    title: "Learning and Growth",
    description:
      "We embrace a culture of continuous learning, where challenges are opportunities for growth. Mentorship encourages personal and collective development.",
  },
  {
    icon: "🤝",
    title: "Unity in Diversity",
    description:
      "Celebrating diverse backgrounds and ideas, we believe strength lies in differences. Inclusivity ensures all voices are valued.",
  },
  {
    icon: "🏋️",
    title: "Resilient Action",
    description:
      "We tackle challenges head-on, remaining adaptable and determined. Teamwork helps us push forward and stay mission-focused.",
  },
  {
    icon: "🔄",
    title: "Embrace Change",
    description:
      "We see change as a path to growth and innovation, staying open to new ideas and evolving needs.",
  },
];

const CoreValues = () => {
  return (
    <div className="bg-gray-100 py-12 px-6">
      <h2 className="text-4xl font-bold text-center mb-10 uppercase">
        Our Core Values
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {coreValues.map((value, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center hover:shadow-xl transition-shadow"
          >
            {/* Icon */}
            <div className="text-6xl text-blue-500 mb-4">{value.icon}</div>
            {/* Title */}
            <h3 className="text-xl font-semibold text-gray-800 mb-4 text-center">
              {value.title}
            </h3>
            {/* Description */}
            <p className="text-gray-600 text-center">{value.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoreValues;

import React from "react";
import DonorsCard from "../../Components/Cards/DonorsCard";
const testImage = "https://via.placeholder.com/150";

const Donors = () => {
  const donorsData = [
    {
      id: 1,
      image: testImage,
      name: "John Doe",
      designation: "CEO, Charity Foundation",
      sayings:
        "Donating to this organization has been a fulfilling experience. Their dedication to the cause is inspiring.",
    },
    {
      id: 2,
      image: testImage,
      name: "Jane Smith",
      designation: "Philanthropist",
      sayings:
        "The work they do is truly transformative. I am proud to be a part of their mission.",
    },
    {
      id: 3,
      image: testImage,
      name: "Jane Smith",
      designation: "Philanthropist",
      sayings:
        "The work they do is truly transformative. I am proud to be a part of their mission.",
    },
    {
      id: 4,
      image: testImage,
      name: "John Doe",
      designation: "Philanthropist",
      sayings:
        "The work they do is truly transformative. I am proud to be a part of their mission.",
    },
    {
      id: 5,
      image: testImage,
      name: "Ton Koe",
      designation: "Philanthropist",
      sayings:
        "The work they do is truly transformative. I am proud to be a part of their mission.",
    },
    {
      id: 6,
      image: testImage,
      name: "Tane Smith",
      designation: "Philanthropist",
      sayings:
        "The work they do is truly transformative. I am proud to be a part of their mission.",
    },
    // Add more donors as needed
  ];

  return (
    <section className="p-8 bg-gray-100">
      <h2 className="text-2xl font-bold text-center mb-8 uppercase">
        Our Donors
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {donorsData.map((donor) => (
          <DonorsCard
            key={donor.id}
            image={donor.image}
            name={donor.name}
            designation={donor.designation}
            sayings={donor.sayings}
          />
        ))}
      </div>
    </section>
  );
};

export default Donors;

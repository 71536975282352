import React from "react";
import { FaHeart, FaEye } from "react-icons/fa";

const CompletedCard = ({ title, date, views, likes, image, subtitle }) => {
  return (
    <div className="relative w-full sm:w-72 md:w-80 lg:w-96 bg-white shadow-md rounded-lg overflow-hidden group">
      {/* Transparent sliding curtain */}
      <div className="absolute inset-0 bg-gray-600 bg-opacity-50 transform translate-x-0 group-hover:translate-x-full transition-transform duration-500 ease-in-out"></div>

      {/* Card image */}
      <img src={image} alt={title} className="w-full h-40 object-cover" />

      {/* Content */}
      <div className="p-4 relative z-10">
        <h2 className="text-sm text-gray-500">{subtitle}</h2>
        <p className="text-gray-500 text-xs">{date}</p>
        <h3 className="text-lg font-semibold mt-2">{title}</h3>
      </div>

      {/* Footer */}
      <div className="px-4 pb-4 flex items-center justify-between text-gray-600 relative z-10">
        <div className="flex items-center gap-2">
          <FaEye />
          <span>{views}</span>
        </div>
        <div className="flex items-center gap-2">
          <FaHeart />
          <span>{likes}</span>
        </div>
      </div>
    </div>
  );
};

export default CompletedCard;
